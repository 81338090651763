<template>
  <div class="imgsBox">
    <div class="headerNav">
      <el-tabs v-model="isShow" class="tabsBox">
        <el-tab-pane
          :label="$t(`message.tabs.ratesTitleB`)"
          name="first"
        ></el-tab-pane>
        <el-tab-pane
          :label="$t(`message.tabs.ratesTitleA`)"
          name="second"
        ></el-tab-pane>
      </el-tabs>
      <div class="unit">{{ $t("message.tabs.ratesUnit") }}</div>
    </div>
    <div class="rates">
      <div class="contentBox">
        <AuditRule :class="{ ohMove: isShow === 'second' }" />
        <TaxRevenueRule />
      </div>
    </div>
    <div class="zizhi">{{ $t("message.tabs.certification") }}：</div>
    <div class="top">
      <el-image
        :src="require('@/assets/image/四川科道衡会计师事务所营业执照.png')"
        :preview-src-list="[
          require('@/assets/image/四川科道衡会计师事务所营业执照.png'),
        ]"
      >
      </el-image>
      <el-image
        :src="require('@/assets/image/营业执照副本-四川科道衡税务师事务所.jpg')"
        :preview-src-list="[
          require('@/assets/image/营业执照副本-四川科道衡税务师事务所.jpg'),
        ]"
      >
      </el-image>
    </div>
    <div class="top bottomImage">
      <div class="left">
        <el-image
          :src="require('@/assets/image/四川科道衡会计师事务所执业证书.png')"
          :preview-src-list="[
            require('@/assets/image/四川科道衡会计师事务所执业证书.png'),
          ]"
        >
        </el-image>
        <el-image
          :src="require('@/assets/image/科道衡工程管理有限公司营业执照.png')"
          :preview-src-list="[
            require('@/assets/image/科道衡工程管理有限公司营业执照.png'),
          ]"
        >
        </el-image>
      </div>

      <el-image
        :src="
          require('@/assets/image/四川科道衡税务师事务所行政登记证书-副本.jpg')
        "
        :preview-src-list="[
          require('@/assets/image/四川科道衡税务师事务所行政登记证书-副本.jpg'),
        ]"
      >
      </el-image>
    </div>
  </div>
</template>
<script>
import TaxRevenueRule from "@/views/component/TaxRevenueRule.vue";
import AuditRule from "@/views/component/AuditRule.vue";
export default {
  components: { TaxRevenueRule, AuditRule },
  data() {
    return {
      isShow: "first",
    };
  },
};
</script>
<style lang="scss" scoped>
.imgsBox {
  width: 1280px;
  margin: 0 auto 60px;
  text-align: center;

  .headerNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #666;
  }

  .rates {
    width: 1280px;
    margin-top: 30px;
    margin-bottom: 40px;
    border-radius: 5px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;

    .contentBox {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
    }

    .ohMove {
      margin-left: -100%;
    }

    .btnBox {
      width: auto;
      position: relative;

      i {
        position: absolute;
        top: 50%;
        right: 0;
        font-size: 68px;
        transform: translateY(-50%);
        color: #d5d3d3;
      }
    }
  }

  .zizhi {
    padding-top: 50px;
    margin-bottom: 30px;
    text-align: left;
    font-size: 28px;
    color: #666;
  }

  .top {
    width: 848px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .el-image {
      width: 364px;
      flex-shrink: 0;
    }
  }

  .bottomImage {
    margin-top: 50px;

    .left {
      text-align: left;
    }
  }
}

::v-deep.tabsBox .el-tabs__nav-wrap::after {
  display: none;
}

::v-deep.tabsBox .el-tabs__header {
  margin: 0;
}

::v-deep.tabsBox .el-tabs__item {
  font-size: 18px;
}
</style>