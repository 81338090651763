var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgsBox"},[_c('div',{staticClass:"headerNav"},[_c('el-tabs',{staticClass:"tabsBox",model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('el-tab-pane',{attrs:{"label":_vm.$t(`message.tabs.ratesTitleB`),"name":"first"}}),_c('el-tab-pane',{attrs:{"label":_vm.$t(`message.tabs.ratesTitleA`),"name":"second"}})],1),_c('div',{staticClass:"unit"},[_vm._v(_vm._s(_vm.$t("message.tabs.ratesUnit")))])],1),_c('div',{staticClass:"rates"},[_c('div',{staticClass:"contentBox"},[_c('AuditRule',{class:{ ohMove: _vm.isShow === 'second' }}),_c('TaxRevenueRule')],1)]),_c('div',{staticClass:"zizhi"},[_vm._v(_vm._s(_vm.$t("message.tabs.certification"))+"：")]),_c('div',{staticClass:"top"},[_c('el-image',{attrs:{"src":require('@/assets/image/四川科道衡会计师事务所营业执照.png'),"preview-src-list":[
        require('@/assets/image/四川科道衡会计师事务所营业执照.png'),
      ]}}),_c('el-image',{attrs:{"src":require('@/assets/image/营业执照副本-四川科道衡税务师事务所.jpg'),"preview-src-list":[
        require('@/assets/image/营业执照副本-四川科道衡税务师事务所.jpg'),
      ]}})],1),_c('div',{staticClass:"top bottomImage"},[_c('div',{staticClass:"left"},[_c('el-image',{attrs:{"src":require('@/assets/image/四川科道衡会计师事务所执业证书.png'),"preview-src-list":[
          require('@/assets/image/四川科道衡会计师事务所执业证书.png'),
        ]}}),_c('el-image',{attrs:{"src":require('@/assets/image/科道衡工程管理有限公司营业执照.png'),"preview-src-list":[
          require('@/assets/image/科道衡工程管理有限公司营业执照.png'),
        ]}})],1),_c('el-image',{attrs:{"src":require('@/assets/image/四川科道衡税务师事务所行政登记证书-副本.jpg'),"preview-src-list":[
        require('@/assets/image/四川科道衡税务师事务所行政登记证书-副本.jpg'),
      ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }