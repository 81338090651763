<!-- i18n -->
<template>
    <div class="select">
        <el-select v-model="selectValue" @change="langChange" placeholder="请选择" name="select-name" size="small">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
    </div>
</template>
 
<script>
export default {
    data() {
        return {
            selectValue: "",
            options: [
                {
                    value: "cn",
                    label: "中 文",
                },
                {
                    value: "en",
                    label: "English",
                },
            ],
        };
    },
    created() {
        let that = this;
        that.selectValue = localStorage.lang == undefined ? "cn" : localStorage.lang;
    },
    methods: {
        //语言切换
        langChange(e) {
            localStorage.setItem("lang", e);
            this.$i18n.locale = e;
            this.$emit('changeLang')
        },
    },
};
</script>
<style lang="scss" scoped>
.select{
    position: relative;
    right: -12px;
}
.el-select {
    width: 90px;
    height: 32px;
    background-color: inherit;
    color: #ffffff;
}
::v-deep.el-select .el-input--suffix {
    height: 32px;
}
::v-deep.el-select .el-select__caret {
    font-size: 12px;
    color: #ffffff;
}
::v-deep.el-select .el-input__inner {
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    background: inherit;
    color: #ffffff;
    border: none;
    user-select: none;
}
.el-select:focus {
    border: none;
}
.el-select-dropdown .el-select-dropdown__item{
    height: 20px;
    line-height: 20px;
    font-size: 12px;
}
</style>