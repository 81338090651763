<template>
    <div class="copyrightBox">
        {{$t('message.tabs.bottom')}}
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.copyrightBox{
    width: 100%;
    height: 60px;
    background: black;
    color: #EFEFEF;
    font-size: 12px;
    text-align: center;
    line-height: 60px;
}
</style>