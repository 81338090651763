<template>
    <div class="aboutWeBox">
        <div class="topImgBox">
            <img :src="require('@/assets/image/首页_08.png')" alt="">
        </div>
        <div class="content">
            <div class="first">
                <img :src="require('@/assets/image/首页_12.png')" alt="" @click="changeTab($t('message.tabs.profile'))">
            </div>
            <div class="second">
                <div><img :src="require('@/assets/image/首页_14.png')" alt="" @click="changeTab($t('message.tabs.framework'))"></div>
                <div><img :src="require('@/assets/image/首页_19.png')" alt="" @click="changeTab($t('message.tabs.manner'))"></div>
            </div>
            <div class="third">
                <img :src="require('@/assets/image/首页_16.png')" alt="" @click="changeTab($t('message.tabs.range'))">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        changeTab(val) {
            this.$emit('changeTab',val)
        }
    },
}
</script>
<style lang="scss" scoped>
.aboutWeBox{
    width: 1280px;
    margin: 50px auto 90px;
    text-align: center;
    .topImgBox{
        margin: auto;
    }
    .content{
        display: flex;
        justify-content: space-between;
        .second{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    img{
        cursor: pointer;
    }
}
</style>