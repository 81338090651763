import zhLocale from 'element-ui/lib/locale/lang/zh-CN' //引入element语言包
//需要转换的中文
const cn = {
    message: {
        tabs: {
            home: '首页',
            profile: '公司简介',
            range: '业务范围',
            partner: '主要客户',
            manner: '企业文化',
            contact: '联系我们',
            rates: '收费查询',
            framework: '组织架构',
            companyName: '四川科道衡会计师事务所',
            companyNameOne: '四 川 科 道 衡 会 计 师 事 务 所 有 限 公 司',
            companyNameTo: '四 川 科 道 衡 税 务 师 事 务 所 有 限 公 司',
            companyNameThree: '四 川 科 道 衡 工 程 管 理 有 限 公 司',
            service: '客服服务专线',
            phone: '电 话',
            mobile: '手 机',
            fax: '传 真',
            email: '邮 箱',
            address: '地 址',
            addressText: "成都市青羊区光华西五路55号2栋1单元13楼1316号",
            bottom: "版权所有：四川科道衡会计师事务所有限公司 备案号：蜀ICP备2023027968号-1 技术支持： 四川信华腾科技有限公司",
            certification: '单位资质',
            ratesTitleA: '税务类服务收费标准',
            ratesTitleB: '审计类服务收费标准',
            floatNumCheck: '最多输入两位小数',
            ratesUnit: '计算单位： 万元',
            ratesSmall: '万元',
            landSmall: '平方米',
            timeSmall: '小时',
            explain: '注：'
        },
        profile: {
            first: '四川科道衡会计师事务所有限公司（以下简称科道衡）前身为四川光达会计师事务所有限公司（曾用名：四川嘉辰信会计师事务所有限公司），成立于2001年9月，是经四川省财政厅批准成立，受四川省注册会计师协会的直接领导和管理的，具有独立法人资格的专业服务机构。',
            second: '20年来，科道衡已为众多的客户提供了大量专业服务，赢得了社会各界的普遍赞誉和高度信赖，在公众心中树立起了诚信、效率、独立、客观、公正的专业形象。四川科道衡会计师事务所有限公司历年被四川省财政厅推荐作为国有大中型企业的审计单位；2015年被四川省国有资产监督管理委员会列入中介机构备选库；2023-2025年成都市国资委中介服务机构。四川科道衡会计师事务所有限公司是成都市财政局的入库机构，是四川省省级单位财政绩效评价第三方入库机构。',
            third: '科道衡服务对象涵盖了国有企业、股份制企业、外商投资企业、集体企业、私营（民营）企业、事业单位、国家机关、团体法人及其他经济组织等。',
            fourth: '由于全体员工卓有成效的工作，科道衡自成立以来，从未涉及诉讼和处罚，维护了国家、投资方、企业经营者、债权债务人等各方应有的利益，得到了社会各界的好评，在同行及社会各界树立了良好的声誉。',
            fiveth: '四川科道衡会计师事务所有限公司2001年经四川省财政厅川财会（2001）1112号文批准成立，在成都市青羊区工商行政管理局登记注册，是具有独立法人资格的专业服务机构，法定代表人周兵。公司注册资本为人民币壹佰零柒万元。',
            sixth: '科道衡人才济济，专业服务多样化，拥有中国注册会计师、全国注册造价工程师、注册资产评估师、注册税务师、房地产估价师、土地估价师、高级会计师、高级工程师、经济师等各类专业人员数十名。具有中华人民共和国财政部颁发的注册会计师执业资格证书（证书号51010120），业务水平高，知识面广，工作能力强，具有良好的职业道德。公司拥有一支诚信、担当、团结、活力的管理团队。目前公司具有专业团队50余人，所学涵盖财务会计、专项审计、内控审计、经济管理、工程审计等专业的知识背景，从业时间均在十年以上。公司团队具有深厚的理论功底、丰富的专业经验、精湛的职业素养、出色的管理能力。公司重视客户需求，坚守质量导向，注重以人为本和团结协作，全面高效的为客户提供各种优质专业的服务。'
        },
        range: {
            audit: '审计',
            auditText: {
                first: '· 股份制改组上市的审计、验资、盈利预测审核、内部控制评价',
                second: '· 独立财务顾问报告',
                third: '· 各类企业的会计报表审计、审阅、内部审计',
                fourth: '· 企业清算会计报表审计',
                fiveth: "· 对舞弊或差错的特种调查",
                sixth: '· 参与经济纠纷调解、协助鉴别民商案件证据',
                seventh: '· 其他各种特定目的审计及其他保证性服务',
            },
            financial: "财务顾问、管理咨询",
            financialText: {
                first: "· 股份公司财务顾问",
                second: "· 外商投资企业财务顾问",
                third: "· 创业（风险）投资专业咨询",
                fourth: "· 企业风险评估",
                fiveth: "· 项目可行性研究和项目评价",
                sixth: " · 绩效评估、考核咨询",
                seventh: "· 为企业管理、重组、清算等经济活动提供专业意见",
            },
            consultation: "税务咨询",
            consultationText: {
                first: "· 企业纳税筹划",
                second: "· 纳税合规性审查及其他专项税务调查",
                third: "· 与购并有关的税务咨询",
                fourth: "· 与资产重组有关的税务咨询",
                fiveth: "· 税务代理服务",
                sixth: "· 专业培训服务",
            },
            enterprise: "企业购并与资产重组",
            enterpriseText: {
                first: "· 参与制定公司并购/重组方案",
                second: "· 提供并购/重组所涉及税务、财务、会计问题的解决方案",
                third: "· 帮助寻找和筛选目标企业",
                fourth: "· 实施审慎性调查",
                fiveth: "· 参与合同谈判"
            },
            performance: "绩效评价",
            performanceText: {
                first: "· 事前绩效评价",
                second: "· 部门支出绩效评价",
                third: "· 项目支出绩效评价",
                fourth: "· 财政政策绩效评价",
                fiveth: "· 企业绩效评价"
            },
            infrastructure: "基建审计",
            infrastructureText: {
                first: "· 编制建设项目的项目建议书、可行性研究报告、工程初步设计文件",
                second: "· 评估与咨询建设项目的项目建议书、可行性研究报告、工程初步设计文件",
                third: "· 建设项目投资管理与估算",
                fourth: "· 建设项目工程概（预）算的编制与审核",
                fiveth: "· 建设项目土建、安装、市政工程的标底编制与审核",
                sixth: "· 建设项目招投标文件的编制与服务",
                seventh: "· 国有和集体投资工程施工合同价的审查",
                eighth: "· 审核材料、设备、工具、器具订货价格的真实性",
                ninth: "· 审核变更签证、洽商记录的有效性",
                tenth: "· 审查工程设计变更、现场洽商记录对工程造价增减调整的影响",
                eleventh: "· 审核工程施工合同、协议的合法、合理性",
                twelfth: "· 建筑安装工程形象进度结算编制与审计",
                thirteenth: "· 工程预付款、备料款、进度款等价款预（结）算管理",
                fourteenth: "· 建设项目工程造价动态管理",
                fifteenth: "· 建设项目工程索赔的审核",
                sixteenth: "· 建设项目造价咨询与工程项目施工管理建议",
                seventeenth: "· 工程竣工结算审价",
                eighteenth: "· 建设项目价值评估"
            }
        },
        partner: {
            title: "二十多年来，科道衡已为众多客户提供了各项专业服务，赢得了社会各界的普遍赞誉和高度信赖，在公众心中树立起了诚信、效率、独立、客观、公正的良好企业形象。",
            enterprise: "企业",
            scjt: "四川交投集团",
            scnt: "四川能投集团",
            schg: "四川化工控股集团",
            scch: "四川川化集团",
            sljc: "双流机场集团",
            kdjc: "康定机场集团",
            gwsc: "国网四川省电力公司",
            zgdx: "中国电信四川公司",


            scgp: "四川高频集团公司",
            scxx: "四川新兴集团公司",
            scsd: "四川圣达集团公司",
            schnwk: "四川华能万康电力有限公司",
            schd: "四川恒鼎实业有限公司",
            scxc: "四川新川航空仪器有限公司",


            schn: "四川华能电力有限公司",
            sccz: "四川川咨建设工程咨询有限责任公司",
            scyl: "四川欲龙股份有限公司",
            cdjg: "成都建工集团",
            cdct: "成都城投集团",
            cdxc: "成都兴城集团",
            cdjl: "成都菊乐（企业）集团",

            zgcs: "中国测试技术研究院",
            zccs: "中测测试科技有限公司",
            pxgy: "郫县国有资产投资管理公司",
            cdth: "成都棠湖集团",
            cdly: "成都联益集团公司",
            cdsy: "成都双羽集团",
            cdee: "成都二峨实业股份有限公司",

            xzsy: "行政事业单位",
            scsg: "四川省国资委",
            scsj: "四川省经信厅",
            scsc: "四川省财政厅",
            scsjd: "四川省戒毒管理局",
            scsyj: "四川省冶金工业厅",
            sckx: "四川科学技术协会",
            scsym: "四川省移民办公室",
            cdsg: "成都市国资委",


            cdsjx: "成都市经信局",
            cdscz: "成都市财政局",
            cdsty: "成都市体育局",
            whqsj: "武侯区审计局",
            pxczj: "郫县财政局",
            pxsjj: "郫县审计局",
            pxdsj: "郫县地税局",
            wjsjj: "温江审计局",
            ncsjx: "南充市经信局",
            lszjx: "凉山州经信局",

        },
        manner: {
            companyaim: "1.公司宗旨",
            serviceConcept: "2.服务理念",
            companyPrinciples: "3.公司原则",
            one: "科道衡以“独立、客观、公正、服务、质量、效率”为公司宗旨。科道衡作为专业服务机构，以服务为导向，强调服务的质量，提升服务的效率。切实为广大客户提供务实、高效且有质量保障的服务。",
            two: "科道衡坚持为客户“发现问题、分析问题、解决问题”的服务理念。科道衡坚持不走形式，实实在在为客户服务。对每一个客户，我们都要认真分析，帮助客户梳理、规范和完善。能够为客户发现问题、分析问题并找到解决问题的方法是我们实现服务理念的最大价值体现。",
            three: "科道衡坚守“不出假报告”的底限和原则。不弄虚作假，严格遵守行业的保密制度。公司人员在从业过程中不接受任何礼品、现金及实物；坚持质量第一，确保项目结果的准确性。公司派出的项目组每一个成员都是具有较高学识水平与专业技能的高、中级专业人才，对项目中的每一个数据都以认真负责的态度，逐一核对，保证项目结果的准确性和科学性。"
        },
        contact: {
            name: "四川科道衡会计师事务所有限公司",
            address: "地址：成都市青羊区光华西五路55号2栋1单元13楼1316号",
            linkMan: "联系人：周兵",
            specialPlane: '座机：(028) 85189698',
            phone: "电话：13980891763、19308182819",
            fax: "传真：(028) 85189698",
        },
        ratesItem: {
            taxConsultation: '税务咨询',
            consultant: '专业税务顾问',
            taxPlanning: '税务策划',
            taxSettlement: '企业所得税汇算清缴鉴证',
            verification: '企业资产损失税前扣除鉴证',
            taxLiquidation: '土地增值税清算鉴证',
            qyzxqs: '企业注销清算鉴证',
            hourly: '计时收费',

            accountingRuleA: '输入金额为资产总额或收入总额，遵循就高原则',
            accountingRuleB: '按税务咨询150%收费，根据疑难事项按200%收费',
            accountingRuleC: '基本收费参照专业税务顾问，加上浮动收费',
            accountingRuleD: '请输入清算鉴证所涉及面积',
            accountingRuleE: '请输入预估所需时长',
            accountingRuleF: '不含简易和简化注销',
            accountingRuleG: '请输入资产账面成本',


            holderBig: '请输入金额',
            holderLand: '请输入土地面积',
            holderTime: '请输入时长',
        },
        ratesItemTo: {
            taxConsultation: '年度财务报表审计',
            consultant: '验资',
            taxPlanning: '经济责任审计',
            taxSettlement: '改制审计',
            verification: '财务收支审计',
            taxLiquidation: '基本建设工程决算审核',
            qyzxqs: '法律法规规定的其他审计业务',
            hourly: '合并分立清算审计',
            financialStatement: '财务报表审阅',
            hourlyRate: '按时收费',

            accountingRuleA: '输入金额为资产总额或收入总额，遵循就高原则',
            accountingRuleB: '按税务咨询150%收费，根据疑难事项按200%收费',
            accountingRuleC: '基本收费参照专业税务顾问，加上浮动收费',
            accountingRuleD: '请输入清算鉴证所涉及面积',
            accountingRuleE: '请输入预估所需时长',
            accountingRuleF: '不含简易和简化注销',
            accountingRuleG: '请输入资产账面成本',
            accountingRuleH: '涉及财务报表审计',
            accountingRuleI: '不涉及财务报表审计',
            accountingRuleJ: '请输入所需验证资金金额',


            holderBig: '请输入金额',
            holderLand: '请输入土地面积',
            holderTime: '请输入时长',
        },
        info: {
            userinfo: '用户信息'
        },
        table: {
            item_number: "项目编号"
        }
    },
    ...zhLocale
}

export default cn;