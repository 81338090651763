<template>
    <div class="rootBox">
        <div class="tableBox">
            <div class="itemBox" v-show="titleText===$t(`message.ratesItemTo.taxConsultation`)">
                <div class="msgOrInp">
                    <div class="introduce">
                        <el-select v-model="titleText">
                            <el-option v-for="(item,index) in options" :key="item.val" :label="item.text" :value="item.text">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="inpBox">
                        <el-form :model="initData">
                            <el-form-item prop="taxNumber" class="taxInp">
                                <el-input clearable v-model="initData.taxNumber" :placeholder="$t(`message.ratesItemTo.holderBig`)" @blur="floatNumMax('taxNumber')" type="number"></el-input>
                                <span class="unitSmall">{{$t("message.tabs.ratesSmall")}}</span>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="explain" :title="$t(`message.ratesItemTo.accountingRuleA`)">{{$t(`message.tabs.explain`)}}&nbsp; {{$t(`message.ratesItemTo.accountingRuleA`)}}</div>
                </div>
                <div class="resultBox">
                    <div class="showPrice">{{stopUndefined(taxPrice)}} </div>
                    <div class="unitText"> &nbsp; {{$t(`message.tabs.ratesSmall`)}}</div>
                </div>
            </div>
            <div class="itemBox" v-show="titleText===$t(`message.ratesItemTo.consultant`)">
                <div class="msgOrInp">
                    <div class="introduce">
                        <el-select v-model="titleText">
                            <el-option v-for="(item,index) in options" :key="item.val" :label="item.text" :value="item.text">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="inpBox">
                        <el-form :model="initData" :inline="true" :rules="initDataRules" class="selectDifficulty">
                            <!-- <el-form-item class="beforeSel">
                                <el-select v-model="difficulty">
                                    <el-option label="普通" :value="1.5"></el-option>
                                    <el-option label="疑难" :value="2"></el-option>
                                </el-select>
                            </el-form-item> -->
                            <el-form-item prop="taxAdvisorNumber" class="taxInp">
                                <el-input clearable v-model="initData.taxAdvisorNumber" :placeholder="$t(`message.ratesItemTo.holderBig`)" type="number" @blur="floatNumMax('taxAdvisorNumber')">
                                </el-input>
                                <span class="unitSmall">{{$t("message.tabs.ratesSmall")}}</span>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="explain" :title="$t(`message.ratesItemTo.accountingRuleJ`)">{{$t(`message.tabs.explain`)}}&nbsp; {{$t(`message.ratesItemTo.accountingRuleJ`)}}</div>
                </div>
                <div class="resultBox">
                    <div class="showPrice">{{stopUndefined(taxAdvisorPrice)}}</div>
                    <div class="unitText"> &nbsp; {{$t(`message.tabs.ratesSmall`)}}</div>
                </div>
            </div>
            <div class="itemBox" v-show="titleText===$t(`message.ratesItemTo.taxPlanning`)">
                <div class="msgOrInp">
                    <div class="introduce">
                        <el-select v-model="titleText">
                            <el-option v-for="(item,index) in options" :key="item.val" :label="item.text" :value="item.text">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="inpBox">
                        <el-form :model="initData" :rules="initDataRules">
                            <el-form-item prop="taxPlanningNumber" class="taxInp">
                                <el-input clearable v-model="initData.taxPlanningNumber" :placeholder="$t(`message.ratesItemTo.holderBig`)" @blur="floatNumMax('taxPlanningNumber')" type="number"></el-input>
                                <span class="unitSmall">{{$t("message.tabs.ratesSmall")}}</span>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="explain" :title="$t(`message.ratesItemTo.accountingRuleA`)">{{$t(`message.tabs.explain`)}}&nbsp; {{$t(`message.ratesItemTo.accountingRuleA`)}}</div>
                </div>
                <div class="resultBox">
                    <div class="showPrice">{{stopUndefined(taxPlanningPrice)}}</div>
                    <div class="unitText"> &nbsp; {{$t(`message.tabs.ratesSmall`)}}</div>
                </div>
            </div>
            <div class="itemBox" v-show="titleText===$t(`message.ratesItemTo.taxSettlement`)">
                <div class="msgOrInp">
                    <div class="introduce">
                        <el-select v-model="titleText">
                            <el-option v-for="(item,index) in options" :key="item.val" :label="item.text" :value="item.text">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="inpBox">
                        <el-form :model="initData" :rules="initDataRules">
                            <el-form-item prop="taxCorporateIncomeNumber" class="taxInp">
                                <el-input clearable v-model="initData.taxCorporateIncomeNumber" :placeholder="$t(`message.ratesItemTo.holderBig`)" @blur="floatNumMax('taxCorporateIncomeNumber')" type="number"></el-input>
                                <span class="unitSmall">{{$t("message.tabs.ratesSmall")}}</span>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="explain" :title="$t(`message.ratesItemTo.accountingRuleA`)">{{$t(`message.tabs.explain`)}}&nbsp; {{$t(`message.ratesItemTo.accountingRuleA`)}}</div>
                </div>
                <div class="resultBox">
                    <div class="showPrice">{{stopUndefined(taxCorporateIncomePrice)}}</div>
                    <div class="unitText"> &nbsp; {{$t(`message.tabs.ratesSmall`)}}</div>
                </div>
            </div>
            <div class="itemBox" v-show="titleText===$t(`message.ratesItemTo.verification`)">
                <div class="msgOrInp">
                    <div class="introduce">
                        <el-select v-model="titleText">
                            <el-option v-for="(item,index) in options" :key="item.val" :label="item.text" :value="item.text">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="inpBox">
                        <el-form :model="initData" :rules="initDataRules">
                            <el-form-item prop="taxCorporateAssetLossNumber" class="taxInp">
                                <el-input clearable v-model="initData.taxCorporateAssetLossNumber" :placeholder="$t(`message.ratesItemTo.holderBig`)" @blur="floatNumMax('taxCorporateAssetLossNumber')" type="number"></el-input>
                                <span class="unitSmall">{{$t("message.tabs.ratesSmall")}}</span>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="explain" :title="$t(`message.ratesItemTo.accountingRuleA`)">{{$t(`message.tabs.explain`)}}&nbsp; {{$t(`message.ratesItemTo.accountingRuleA`)}}</div>
                </div>
                <div class="resultBox">
                    <div class="showPrice">{{stopUndefined(taxCorporateAssetLossPrice)}}</div>
                    <div class="unitText"> &nbsp; {{$t(`message.tabs.ratesSmall`)}}</div>
                </div>
            </div>
            <div class="itemBox" v-show="titleText===$t(`message.ratesItemTo.taxLiquidation`)">
                <div class="msgOrInp">
                    <div class="introduce">
                        <el-select v-model="titleText">
                            <el-option v-for="(item,index) in options" :key="item.val" :label="item.text" :value="item.text">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="inpBox taxInp">
                        <el-input clearable v-model="initData.taxIncrementNumber" :placeholder="$t(`message.ratesItemTo.holderBig`)" @blur="floatNumMax('taxIncrementNumber')" type="number"></el-input>
                        <span class="qgSmall">{{$t("message.tabs.ratesSmall")}}</span>
                    </div>
                    <div class="explain" :title="$t(`message.ratesItemTo.accountingRuleA`)">{{$t(`message.tabs.explain`)}}&nbsp; {{$t(`message.ratesItemTo.accountingRuleA`)}}</div>
                </div>
                <div class="resultBox">
                    <div class="showPrice">{{stopUndefined(taxIncrementPrice)}}</div>
                    <div class="unitText"> &nbsp; {{$t(`message.tabs.ratesSmall`)}}</div>
                </div>
            </div>
            <div class="itemBox" v-show="titleText===$t(`message.ratesItemTo.qyzxqs`)">
                <div class="msgOrInp">
                    <div class="introduce">
                        <el-select v-model="titleText">
                            <el-option v-for="(item,index) in options" :key="item.val" :label="item.text" :value="item.text">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="inpBox">
                        <el-form :model="initData" :rules="initDataRules">
                            <el-form-item prop="cancellationEnterpriseNumber" class="taxInp">
                                <el-input clearable v-model="initData.cancellationEnterpriseNumber" :placeholder="$t(`message.ratesItemTo.holderBig`)" @blur="floatNumMax('cancellationEnterpriseNumber')" type="number"></el-input>
                                <span class="unitSmall">{{$t("message.tabs.ratesSmall")}}</span>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="explain" :title="$t(`message.ratesItemTo.accountingRuleA`)">{{$t(`message.tabs.explain`)}}&nbsp; {{$t(`message.ratesItemTo.accountingRuleA`)}}</div>
                </div>
                <div class="resultBox">
                    <div class="showPrice">{{stopUndefined(cancellationEnterprisePrice)}}</div>
                    <div class="unitText"> &nbsp; {{$t(`message.tabs.ratesSmall`)}}</div>
                </div>
            </div>
            <div class="itemBox" v-show="titleText===$t(`message.ratesItemTo.hourly`)">
                <div class="msgOrInp">
                    <div class="introduce">
                        <el-select v-model="titleText">
                            <el-option v-for="(item,index) in options" :key="item.val" :label="item.text" :value="item.text">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="inpBox timeCharge taxInp">
                        <!-- <el-select v-model="lever">
                            <el-option label="执业员等级：1" :value="7"></el-option>
                            <el-option label="执业员等级：2" :value="6"></el-option>
                            <el-option label="执业员等级：3" :value="5"></el-option>
                            <el-option label="执业员等级：4" :value="4"></el-option>
                            <el-option label="执业员等级：5" :value="3"></el-option>
                            <el-option label="执业员等级：6" :value="2"></el-option>
                            <el-option label="执业员等级：7" :value="1"></el-option>
                        </el-select> -->
                        <el-input class="timeInp" clearable v-model="initData.travelExpenseNumber" :placeholder="$t(`message.ratesItemTo.holderBig`)" @input="importText('travelExpenseNumber')" type="number">
                        </el-input>
                        <span class="confirmSmall">{{$t("message.tabs.ratesSmall")}}</span>
                    </div>
                    <div class="explain" :title="$t(`message.ratesItemTo.accountingRuleA`)">{{$t(`message.tabs.explain`)}}&nbsp; {{$t(`message.ratesItemTo.accountingRuleA`)}}</div>
                </div>
                <div class="resultBox">
                    <div class="showPrice">{{stopUndefined(travelExpensePrice)}}</div>
                    <div class="unitText"> &nbsp;{{$t(`message.tabs.ratesSmall`)}}</div>
                </div>
            </div>
            <div class="itemBox" v-show="titleText===$t(`message.ratesItemTo.financialStatement`)">
                <div class="msgOrInp">
                    <div class="introduce">
                        <el-select v-model="titleText">
                            <el-option v-for="(item,index) in options" :key="item.val" :label="item.text" :value="item.text">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="inpBox">
                        <el-form :model="initData" :rules="initDataRules" class="financialStatementForm">
                            <el-form-item class="beforeSel">
                                <el-select v-model="difficulty">
                                    <el-option :label="$t(`message.ratesItemTo.accountingRuleH`)" :value="0.0003"></el-option>
                                    <el-option :label="$t(`message.ratesItemTo.accountingRuleI`)" :value="0.0006003"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item prop="financialStatementNumber" class="taxInp">
                                <el-input clearable v-model="initData.financialStatementNumber" :placeholder="$t(`message.ratesItemTo.holderBig`)" @blur="floatNumMax('financialStatementNumber')" type="number"></el-input>
                                <span class="unitSmall">{{$t("message.tabs.ratesSmall")}}</span>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="explain topOne" :title="$t(`message.ratesItemTo.accountingRuleA`)">{{$t(`message.tabs.explain`)}}&nbsp; {{$t(`message.ratesItemTo.accountingRuleA`)}}</div>
                </div>
                <div class="resultBox">
                    <div class="showPrice">{{stopUndefined(financialStatementPrice)}}</div>
                    <div class="unitText"> &nbsp; {{$t(`message.tabs.ratesSmall`)}}</div>
                </div>
            </div>
            <div class="itemBox" v-show="titleText===$t(`message.ratesItemTo.hourlyRate`)">
                <div class="msgOrInp">
                    <div class="introduce">
                        <el-select v-model="titleText">
                            <el-option v-for="(item,index) in options" :key="item.val" :label="item.text" :value="item.text">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="inpBox timeCharge taxInp">
                        <el-select v-model="lever">
                            <el-option label="执业等级6" :value="6"></el-option>
                            <el-option label="执业等级5" :value="5"></el-option>
                            <el-option label="执业等级4" :value="4"></el-option>
                            <el-option label="执业等级3" :value="3"></el-option>
                            <el-option label="执业等级2" :value="2"></el-option>
                            <el-option label="执业等级1" :value="1"></el-option>
                        </el-select>
                        <el-input class="timeInpBox" clearable v-model="initData.hourlyRateNumber" :placeholder="$t(`message.ratesItem.holderTime`)" @input="importText('hourlyRateNumber')" type="number">
                        </el-input>
                        <span class="timeSmall">{{$t("message.tabs.timeSmall")}}</span>
                    </div>
                    <div class="explain topTwo" :title="$t(`message.ratesItemTo.accountingRuleE`)">{{$t(`message.tabs.explain`)}}&nbsp; {{$t(`message.ratesItemTo.accountingRuleE`)}}</div>
                </div>
                <div class="resultBox">
                    <div class="showPrice">{{stopUndefined(hourlyRatePrice)}}</div>
                    <div class="unitText"> &nbsp;{{$t(`message.tabs.ratesSmall`)}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'taxRevenueRule',
    data() {
        return {
            //费率
            rateValueData: {
                taxValue: 2 / 10000, //税务咨询
                taxAdvisorValue: 3 / 10000, //专业税务顾问
                taxPlanningValue: 5 / 10000, //税收策划
                taxIncrementValue: 3, //土地增值税清算鉴证
            },
            //输入的价格,和上面费率的位置一致的
            initData: {
                taxNumber: '',
                taxAdvisorNumber: '',
                taxPlanningNumber: '',
                taxCorporateIncomeNumber: '',
                taxCorporateAssetLossNumber: '',
                taxIncrementNumber: '',
                cancellationEnterpriseNumber: '',
                travelExpenseNumber: '',
                financialStatementNumber: '',
                hourlyRateNumber: ''
            },
            initDataRules: {
                taxNumber: [{ required: true, validator: this.floatNumCheck, trigger: 'change' }],
                taxAdvisorNumber: [{ required: true, validator: this.floatNumCheck, trigger: 'change' }],
                taxPlanningNumber: [{ required: true, validator: this.floatNumCheck, trigger: 'change' }],
                taxCorporateIncomeNumber: [{ required: true, validator: this.floatNumCheck, trigger: 'change' }],
                taxCorporateAssetLossNumber: [{ required: true, validator: this.floatNumCheck, trigger: 'change' }],
                taxIncrementNumber: [{ required: true, validator: this.floatNumCheck, trigger: 'change' }],
                cancellationEnterpriseNumber: [{ required: true, validator: this.floatNumCheck, trigger: 'change' }],
                travelExpenseNumber: [{ required: true, validator: this.floatNumCheck, trigger: 'change' }]
            },
            difficulty: 0.0003,
            lever: 6,
            options: [{
                text: this.$t(`message.ratesItemTo.taxConsultation`),
                val: 0
            }, {
                text: this.$t(`message.ratesItemTo.consultant`),
                val: 1
            }, {
                text: this.$t(`message.ratesItemTo.taxPlanning`),
                val: 2
            }, {
                text: this.$t(`message.ratesItemTo.taxSettlement`),
                val: 3
            }, {
                text: this.$t(`message.ratesItemTo.verification`),
                val: 4
            }, {
                text: this.$t(`message.ratesItemTo.taxLiquidation`),
                val: 5
            }, {
                text: this.$t(`message.ratesItemTo.qyzxqs`),
                val: 6
            }, {
                text: this.$t(`message.ratesItemTo.hourly`),
                val: 7
            }, {
                text: this.$t(`message.ratesItemTo.financialStatement`),
                val: 8
            }, {
                text: this.$t(`message.ratesItemTo.hourlyRate`),
                val: 9
            }],
            titleText: this.$t(`message.ratesItemTo.taxConsultation`)
        };
    },
    computed: {
        /**
         * @negligible :需要减去的不计算价格的部分
         * @rate :不同价格的费率
         * @floorPrice ：底价
         * 展示价格 = floorPrice + (用户输入的价格 - negligible) * rate
         */
        taxPrice() {
            if (this.initData.taxNumber <= 0) return 0
            let negligible, rate, floorPrice;
            if (this.initData.taxNumber <= 100) {
                rate = 0.005
                negligible = 0
                floorPrice = 0
            } else if (this.initData.taxNumber <= 500) {
                rate = 0.0015
                negligible = 100
                floorPrice = 0.5
            } else if (this.initData.taxNumber <= 1000) {
                rate = 0.0008
                negligible = 500
                floorPrice = 1.1
            } else if (this.initData.taxNumber <= 5000) {
                rate = 0.0005
                negligible = 1000
                floorPrice = 1.1
            } else if (this.initData.taxNumber <= 10000) {
                rate = 0.0003
                negligible = 5000
                floorPrice = 3.1
            } else if (this.initData.taxNumber <= 50000) {
                rate = 0.00022
                negligible = 10000
                floorPrice = 4.6
            } else if (this.initData.taxNumber <= 100000) {
                rate = 0.00015
                negligible = 50000
                floorPrice = 13.4
            } else if (this.initData.taxNumber <= 1000000) {
                rate = 0.0001
                negligible = 100000
                floorPrice = 20.9
            } else {
                rate = 0.00006
                negligible = 1000000
                floorPrice = 110.9
            }
            const floatNum = (rate + '').split('.')[1].length
            const initPrice = (((+this.initData.taxNumber) * 100) - (negligible * 100)) / 100
            const price = (initPrice * 100) * (rate * Math.pow(10, floatNum)) / Math.pow(10, (floatNum + 2))
            //未加底价的价格的小数点
            const priceFloat = (price + '').indexOf('.') > 0 ? (price + '').split('.')[1].length : 2
            const endPrice = (price * Math.pow(10, priceFloat) + floorPrice * Math.pow(10, priceFloat)) / Math.pow(10, priceFloat)
            return endPrice > 0.2 ? endPrice : 0.2
        },
        taxAdvisorPrice() {
            if (this.initData.taxAdvisorNumber <= 0) return 0
            let negligible, rate, floorPrice;
            if (this.initData.taxAdvisorNumber <= 100) {
                rate = 0.003
                negligible = 0
                floorPrice = 0
            } else if (this.initData.taxAdvisorNumber <= 500) {
                rate = 0.001
                negligible = 100
                floorPrice = 0.3
            } else if (this.initData.taxAdvisorNumber <= 1000) {
                rate = 0.0005
                negligible = 500
                floorPrice = 0.7
            } else if (this.initData.taxAdvisorNumber <= 5000) {
                rate = 0.0003
                negligible = 1000
                floorPrice = 0.95
            } else if (this.initData.taxAdvisorNumber <= 10000) {
                rate = 0.00025
                negligible = 5000
                floorPrice = 2.15
            } else if (this.initData.taxAdvisorNumber <= 50000) {
                rate = 0.00015
                negligible = 10000
                floorPrice = 3.4
            } else if (this.initData.taxAdvisorNumber <= 100000) {
                rate = 0.00001
                negligible = 50000
                floorPrice = 13.9
            } else if (this.initData.taxAdvisorNumber <= 1000000) {
                rate = 0.000006
                negligible = 100000
                floorPrice = 63
            } else {
                rate = 0.000003
                negligible = 1000000
                floorPrice = 108.9
            }
            const floatNum = (rate + '').split('.')[1].length
            const initPrice = (((+this.initData.taxAdvisorNumber) * 100) - (negligible * 100)) / 100
            const price = (initPrice * 100) * (rate * Math.pow(10, floatNum)) / Math.pow(10, (floatNum + 2))
            //未加底价的价格的小数点
            const priceFloat = (price + '').indexOf('.') > 0 ? (price + '').split('.')[1].length : 2
            const endPrice = (price * Math.pow(10, priceFloat) + floorPrice * Math.pow(10, priceFloat)) / Math.pow(10, priceFloat)
            return endPrice > 0.1 ? endPrice : 0.1
        },
        taxPlanningPrice() {
            if (this.initData.taxPlanningNumber <= 0) return 0
            let negligible, rate, floorPrice;
            if (this.initData.taxPlanningNumber <= 100) {
                rate = 0.005
                negligible = 0
                floorPrice = 0
            } else if (this.initData.taxPlanningNumber <= 500) {
                rate = 0.0015
                negligible = 100
                floorPrice = 0.5
            } else if (this.initData.taxPlanningNumber <= 1000) {
                rate = 0.0008
                negligible = 500
                floorPrice = 1.1
            } else if (this.initData.taxPlanningNumber <= 5000) {
                rate = 0.0005
                negligible = 1000
                floorPrice = 1.1
            } else if (this.initData.taxPlanningNumber <= 10000) {
                rate = 0.0003
                negligible = 5000
                floorPrice = 3.1
            } else if (this.initData.taxPlanningNumber <= 50000) {
                rate = 0.00022
                negligible = 10000
                floorPrice = 4.6
            } else if (this.initData.taxPlanningNumber <= 100000) {
                rate = 0.00015
                negligible = 50000
                floorPrice = 13.4
            } else if (this.initData.taxPlanningNumber <= 1000000) {
                rate = 0.0001
                negligible = 100000
                floorPrice = 20.9
            } else {
                rate = 0.00006
                negligible = 1000000
                floorPrice = 110.9
            }
            const floatNum = (rate + '').split('.')[1].length
            const initPrice = (((+this.initData.taxPlanningNumber) * 100) - (negligible * 100)) / 100
            const price = (initPrice * 100) * (rate * Math.pow(10, floatNum)) / Math.pow(10, (floatNum + 2))
            //未加底价的价格的小数点
            const priceFloat = (price + '').indexOf('.') > 0 ? (price + '').split('.')[1].length : 2
            const endPrice = (price * Math.pow(10, priceFloat) + floorPrice * Math.pow(10, priceFloat)) / Math.pow(10, priceFloat)
            return (endPrice * 1.5) > 0.3 ? (endPrice * 1.5) : 0.3
        },
        taxCorporateIncomePrice() {
            if (this.initData.taxCorporateIncomeNumber <= 0) return 0
            let negligible, rate, floorPrice;
            if (this.initData.taxCorporateIncomeNumber <= 100) {
                rate = 0.005
                negligible = 0
                floorPrice = 0
            } else if (this.initData.taxCorporateIncomeNumber <= 500) {
                rate = 0.0015
                negligible = 100
                floorPrice = 0.5
            } else if (this.initData.taxCorporateIncomeNumber <= 1000) {
                rate = 0.0008
                negligible = 500
                floorPrice = 1.1
            } else if (this.initData.taxCorporateIncomeNumber <= 5000) {
                rate = 0.0005
                negligible = 1000
                floorPrice = 1.1
            } else if (this.initData.taxCorporateIncomeNumber <= 10000) {
                rate = 0.0003
                negligible = 5000
                floorPrice = 3.1
            } else if (this.initData.taxCorporateIncomeNumber <= 50000) {
                rate = 0.00022
                negligible = 10000
                floorPrice = 4.6
            } else if (this.initData.taxCorporateIncomeNumber <= 100000) {
                rate = 0.00015
                negligible = 50000
                floorPrice = 13.4
            } else if (this.initData.taxCorporateIncomeNumber <= 1000000) {
                rate = 0.0001
                negligible = 100000
                floorPrice = 20.9
            } else {
                rate = 0.00006
                negligible = 1000000
                floorPrice = 110.9
            }
            const floatNum = (rate + '').split('.')[1].length
            const initPrice = (((+this.initData.taxCorporateIncomeNumber) * 100) - (negligible * 100)) / 100
            const price = (initPrice * 100) * (rate * Math.pow(10, floatNum)) / Math.pow(10, (floatNum + 2))
            //未加底价的价格的小数点
            const priceFloat = (price + '').indexOf('.') > 0 ? (price + '').split('.')[1].length : 2
            const endPrice = (price * Math.pow(10, priceFloat) + floorPrice * Math.pow(10, priceFloat)) / Math.pow(10, priceFloat)
            return (endPrice * 1.5) > 0.3 ? (endPrice * 1.5) : 0.3
        },
        taxCorporateAssetLossPrice() {
            if (this.initData.taxCorporateAssetLossNumber <= 0) return 0
            let negligible, rate, floorPrice;
            if (this.initData.taxCorporateAssetLossNumber <= 100) {
                rate = 0.005
                negligible = 0
                floorPrice = 0
            } else if (this.initData.taxCorporateAssetLossNumber <= 500) {
                rate = 0.0015
                negligible = 100
                floorPrice = 0.5
            } else if (this.initData.taxCorporateAssetLossNumber <= 1000) {
                rate = 0.0008
                negligible = 500
                floorPrice = 0.8
            } else if (this.initData.taxCorporateAssetLossNumber <= 5000) {
                rate = 0.0005
                negligible = 1000
                floorPrice = 0.98
            } else if (this.initData.taxCorporateAssetLossNumber <= 10000) {
                rate = 0.0003
                negligible = 5000
                floorPrice = 1.98
            } else if (this.initData.taxCorporateAssetLossNumber <= 50000) {
                rate = 0.00022
                negligible = 10000
                floorPrice = 4.48
            } else if (this.initData.taxCorporateAssetLossNumber <= 100000) {
                rate = 0.00015
                negligible = 50000
                floorPrice = 9.98
            } else if (this.initData.taxCorporateAssetLossNumber <= 1000000) {
                rate = 0.0001
                negligible = 100000
                floorPrice = 14.98
            } else {
                rate = 0.00006
                negligible = 1000000
                floorPrice = 84.98
            }
            const floatNum = (rate + '').split('.')[1].length
            const initPrice = (((+this.initData.taxCorporateAssetLossNumber) * 100) - (negligible * 100)) / 100
            const price = (initPrice * 100) * (rate * Math.pow(10, floatNum)) / Math.pow(10, (floatNum + 2))
            //未加底价的价格的小数点
            const priceFloat = (price + '').indexOf('.') > 0 ? (price + '').split('.')[1].length : 2
            const endPrice = (price * Math.pow(10, priceFloat) + floorPrice * Math.pow(10, priceFloat)) / Math.pow(10, priceFloat)
            return (endPrice * 1.5) > 0.3 ? (endPrice * 1.5) : 0.3
        },
        taxIncrementPrice() {
            if (this.initData.taxIncrementNumber <= 0) return 0
            let negligible, rate, floorPrice;
            if (this.initData.taxIncrementNumber <= 100) {
                rate = 0.005
                negligible = 0
                floorPrice = 0
            } else if (this.initData.taxIncrementNumber <= 500) {
                rate = 0.0015
                negligible = 100
                floorPrice = 0.5
            } else if (this.initData.taxIncrementNumber <= 1000) {
                rate = 0.0008
                negligible = 500
                floorPrice = 0.8
            } else if (this.initData.taxIncrementNumber <= 5000) {
                rate = 0.0005
                negligible = 1000
                floorPrice = 0.98
            } else if (this.initData.taxIncrementNumber <= 10000) {
                rate = 0.0003
                negligible = 5000
                floorPrice = 1.98
            } else if (this.initData.taxIncrementNumber <= 50000) {
                rate = 0.00022
                negligible = 10000
                floorPrice = 4.48
            } else if (this.initData.taxIncrementNumber <= 100000) {
                rate = 0.00015
                negligible = 50000
                floorPrice = 9.98
            } else if (this.initData.taxIncrementNumber <= 1000000) {
                rate = 0.0001
                negligible = 100000
                floorPrice = 14.98
            } else {
                rate = 0.00006
                negligible = 1000000
                floorPrice = 84.98
            }
            const floatNum = (rate + '').split('.')[1].length
            const initPrice = (((+this.initData.taxIncrementNumber) * 100) - (negligible * 100)) / 100
            const price = (initPrice * 100) * (rate * Math.pow(10, floatNum)) / Math.pow(10, (floatNum + 2))
            //未加底价的价格的小数点
            const priceFloat = (price + '').indexOf('.') > 0 ? (price + '').split('.')[1].length : 2
            const endPrice = (price * Math.pow(10, priceFloat) + floorPrice * Math.pow(10, priceFloat)) / Math.pow(10, priceFloat)
            return (endPrice * 2) > 0.4 ? (endPrice * 2) : 0.4
        },
        cancellationEnterprisePrice() {
            if (this.initData.cancellationEnterpriseNumber <= 0) return 0
            let negligible, rate, floorPrice;
            if (this.initData.cancellationEnterpriseNumber <= 100) {
                rate = 0.005
                negligible = 0
                floorPrice = 0
            } else if (this.initData.cancellationEnterpriseNumber <= 500) {
                rate = 0.0015
                negligible = 100
                floorPrice = 0.5
            } else if (this.initData.cancellationEnterpriseNumber <= 1000) {
                rate = 0.0008
                negligible = 500
                floorPrice = 0.8
            } else if (this.initData.cancellationEnterpriseNumber <= 5000) {
                rate = 0.0005
                negligible = 1000
                floorPrice = 0.98
            } else if (this.initData.cancellationEnterpriseNumber <= 10000) {
                rate = 0.0003
                negligible = 5000
                floorPrice = 1.98
            } else if (this.initData.cancellationEnterpriseNumber <= 50000) {
                rate = 0.00022
                negligible = 10000
                floorPrice = 4.48
            } else if (this.initData.cancellationEnterpriseNumber <= 100000) {
                rate = 0.00015
                negligible = 50000
                floorPrice = 9.98
            } else if (this.initData.cancellationEnterpriseNumber <= 1000000) {
                rate = 0.0001
                negligible = 100000
                floorPrice = 14.98
            } else {
                rate = 0.00006
                negligible = 1000000
                floorPrice = 84.98
            }
            const floatNum = (rate + '').split('.')[1].length
            const initPrice = (((+this.initData.cancellationEnterpriseNumber) * 100) - (negligible * 100)) / 100
            const price = (initPrice * 100) * (rate * Math.pow(10, floatNum)) / Math.pow(10, (floatNum + 2))
            //未加底价的价格的小数点
            const priceFloat = (price + '').indexOf('.') > 0 ? (price + '').split('.')[1].length : 2
            const endPrice = (price * Math.pow(10, priceFloat) + floorPrice * Math.pow(10, priceFloat)) / Math.pow(10, priceFloat)
            return (endPrice * 1.2) > 0.24 ? (endPrice * 1.2) : 0.24
        },
        travelExpensePrice() {
            if (this.initData.travelExpenseNumber <= 0) return 0
            let negligible, rate, floorPrice;
            if (this.initData.travelExpenseNumber <= 100) {
                rate = 0.005
                negligible = 0
                floorPrice = 0
            } else if (this.initData.travelExpenseNumber <= 500) {
                rate = 0.0015
                negligible = 100
                floorPrice = 0.5
            } else if (this.initData.travelExpenseNumber <= 1000) {
                rate = 0.0008
                negligible = 500
                floorPrice = 0.8
            } else if (this.initData.travelExpenseNumber <= 5000) {
                rate = 0.0005
                negligible = 1000
                floorPrice = 0.98
            } else if (this.initData.travelExpenseNumber <= 10000) {
                rate = 0.0003
                negligible = 5000
                floorPrice = 1.98
            } else if (this.initData.travelExpenseNumber <= 50000) {
                rate = 0.00022
                negligible = 10000
                floorPrice = 4.48
            } else if (this.initData.travelExpenseNumber <= 100000) {
                rate = 0.00015
                negligible = 50000
                floorPrice = 9.98
            } else if (this.initData.travelExpenseNumber <= 1000000) {
                rate = 0.0001
                negligible = 100000
                floorPrice = 14.98
            } else {
                rate = 0.00006
                negligible = 1000000
                floorPrice = 84.98
            }
            const floatNum = (rate + '').split('.')[1].length
            const initPrice = (((+this.initData.travelExpenseNumber) * 100) - (negligible * 100)) / 100
            const price = (initPrice * 100) * (rate * Math.pow(10, floatNum)) / Math.pow(10, (floatNum + 2))
            //未加底价的价格的小数点
            const priceFloat = (price + '').indexOf('.') > 0 ? (price + '').split('.')[1].length : 2
            const endPrice = (price * Math.pow(10, priceFloat) + floorPrice * Math.pow(10, priceFloat)) / Math.pow(10, priceFloat)
            return (endPrice * 1.5) > 0.3 ? (endPrice * 1.5) : 0.3
        },
        financialStatementPrice() {
            if (this.initData.financialStatementNumber <= 0) return 0
            const floatNum = (this.difficulty + '').split('.')[1].length
            const price = (this.initData.financialStatementNumber * 100) * (this.difficulty * Math.pow(10, floatNum)) * 5
            return price / Math.pow(10, (floatNum + 3))
        },
        hourlyRatePrice() {
            const obj = {
                1: 700,
                2: 600,
                3: 500,
                4: 400,
                5: 300,
                6: 200,
            }
            return obj[this.lever] * this.initData.hourlyRateNumber / 10000
        }
    },
    methods: {
        floatNumMax(val) {
            if (this.initData[val] <= 0) {
                this.initData[val] = ''
            } else if (('' + this.initData[val]).indexOf('.') < 0) {
                this.initData[val] = this.initData[val] + '.00'
            } else if (('' + this.initData[val]).split('.')[1].length < 2) {
                this.initData[val] = this.initData[val] + '0'
            } else if (('' + this.initData[val]).split('.')[1].length === 2) {

            } else if (('' + this.initData[val]).split('.')[1].length > 2) {
                const [before, after] = (this.initData[val] + '').split('.')
                this.initData[val] = before + '.' + (after + '').slice(0, 2)
            }
            if ((this.initData[val] + '').length > 20) this.initData[val] = (this.initData[val] + '').slice(0, 20)
        },
        //拒绝非数字的展示
        stopUndefined(val) {
            const num = Number(val)
            if (!num) return '0.00'
            if ((num + '').indexOf('.') < 0) return num + '.00'
            if ((num + '').split('.')[1].length <= 2) return val.toFixed(2)
            const twoFloatPrice = Math.round((num) * 100) / 100
            if ((twoFloatPrice + '').indexOf('.') < 0) {
                return twoFloatPrice + '.00'
            } else if ((twoFloatPrice + '').split('.')[1].length === 1) {
                return twoFloatPrice + '0'
            } else {
                return twoFloatPrice
            }
        },
        //只允许输入数字
        importText(val) {
            this.initData[val] = `${(this.initData[val] + '').replace(/\D/g, '')}`
        },
        floatNumCheck(rule, value, cb) {
            if (/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/.test(value) || value == 0) {
                return cb()
            }
            cb(new Error(this.$t(`message.tabs.floatNumCheck`)))
        },
    },
};
</script>

<style lang="scss" scoped>
.rootBox {
    width: 100%;
    flex-shrink: 0;
    transition: all 0.3s ease;
    .tableBox {
        transition: all 0.3s ease;
        height: 80px;
        overflow: hidden;
        color: #666;
        .itemBox {
            padding: 0 20px;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            .msgOrInp {
                position: relative;
                display: flex;
                .introduce {
                    margin-right: 10px;
                }
                .inpBox {
                    .selectDifficulty {
                        display: flex;
                    }
                    .financialStatementForm {
                        display: flex;
                    }
                    .confirmSmall {
                        white-space: nowrap;
                        position: absolute;
                        right: -38px;
                        top: 5px;
                        font-size: 14px;
                    }
                }
                .taxInp {
                    position: relative;
                    .unitSmall {
                        position: absolute;
                        padding-left: 10px;
                        white-space: nowrap;
                    }
                    .timeSmall {
                        font-size: 14px;
                        position: absolute;
                        right: -10px;
                        top: 5px;
                    }
                    .landSmall {
                        position: absolute;
                        top: 6px;
                        padding-left: 10px;
                        font-size: 14px;
                        white-space: nowrap;
                    }
                    .qgSmall {
                        position: absolute;
                        white-space: nowrap;
                        padding-left: 10px;
                        top: 5px;
                        font-size: 14px;
                    }
                }
                .timeCharge {
                    display: flex;
                    .timeInpBox {
                        margin-left: -20px;
                    }
                }
                .explain {
                    line-height: 40px;
                    font-size: 14px;
                    padding-left: 10px;
                    white-space: nowrap;
                    max-width: 700px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    position: absolute;
                    top: 40px;
                    left: 230px;
                    color: #eb0202;
                }
            }
            .resultBox {
                display: flex;
                line-height: 40px;
                .showPrice {
                    min-width: 122px;
                    height: 38px;
                    line-height: 40px;
                    border-radius: 4px;
                    padding: 0 10px;
                    border: 1px solid #dcdfe6;
                }
            }
        }
    }
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}
::v-deep input[type="'number'"] {
    -moz-appearance: textfield !important;
}
::v-deep .el-input__inner {
    width: 270px;
    padding-right: 70px;
}
::v-deep.el-select .el-input__inner {
    width: 100%;
    padding-right: 30px;
}
::v-deep.introduce .el-select .el-input__inner {
    width: 230px;
}
::v-deep.selectDifficulty .beforeSel .el-input__inner {
    width: 80px;
}
.beforeSel {
    margin-right: 10px;
}
.topOne {
    left: 442px !important;
}
.topTwo {
    left: 384px !important;
}
</style>