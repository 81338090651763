<template>
    <div class="rootBox">
        <header class="headerTop">
            <div class="content">
                <div class="leftTop">{{ $t('message.tabs.companyName') }}</div>
                <div class="rightTop">
                    <span>{{ $t('message.tabs.service') }}：（028）85189698</span>
                    <Cut @changeLang="changeLang" />
                </div>
            </div>
        </header>
        <div class="titleBox">
            <div class="titleLeft">
                <div class="logo">
                    <img :src="require('@/assets/image/logo.png')" alt="">
                </div>
                <div class="name">
                    <p class="cn">{{ $t('message.tabs.companyNameOne') }}</p>
                    <div class="partition"></div>
                    <p class="cn">{{ $t('message.tabs.companyNameTo') }}</p>
                    <div class="partition"></div>
                    <p class="cn">{{ $t('message.tabs.companyNameThree') }}</p>
                </div>
            </div>
            <el-tabs class="tabsBox" v-model="activeName" @tab-click="handleClick">
                <el-tab-pane :label="$t('message.tabs.home')" :name="$t('message.tabs.home')"></el-tab-pane>
                <el-tab-pane :label="$t('message.tabs.profile')" :name="$t('message.tabs.profile')"></el-tab-pane>
                <el-tab-pane :label="$t('message.tabs.range')" :name="$t('message.tabs.range')"></el-tab-pane>
                <el-tab-pane :label="$t('message.tabs.rates')" :name="$t('message.tabs.rates')"></el-tab-pane>
                <el-tab-pane :label="$t('message.tabs.manner')" :name="$t('message.tabs.manner')"></el-tab-pane>
                <el-tab-pane :label="$t('message.tabs.contact')" :name="$t('message.tabs.contact')"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="imgBox">
        </div>
        <!-- 内容title -->
        <TopTabs v-if="activeName !== $t('message.tabs.home')" :title="activeName" @changeTab="changeTabs" />
        <!-- 关于我们 -->
        <AboutWe v-if="activeName === $t('message.tabs.home')" @changeTab="changeTabs" />
        <!-- 公司简介 -->
        <Profile v-if="activeName === $t('message.tabs.profile')" />
        <!-- 业务范围 -->
        <Range v-if="activeName === $t('message.tabs.range')" />
        <!-- 主要客户 -->
        <!-- <Partner v-if="activeName===$t('message.tabs.partner')" /> -->
        <!-- 企业文化 -->
        <Manner v-if="activeName === $t('message.tabs.manner')" />
        <!-- 联系我们 -->
        <ContactUs v-if="activeName === $t('message.tabs.contact')" />
        <!-- 收费标准 -->
        <Rates v-if="activeName === $t('message.tabs.rates')" />
        <!-- 组织架构 -->
        <Framework v-if="activeName === $t('message.tabs.framework')" />
        <!-- 底部详情 -->
        <BaseDetails @changeTabs="changeTabs" />
        <!-- 技术支持 -->
        <Copyright />
    </div>
</template>

<script>
import AboutWe from '@/views/AboutWe/index.vue'
import BaseDetails from '@/views/BaseDetails/index.vue'
import Copyright from '@/views/Copyright/index.vue'
import TopTabs from '@/views/component/TopTabs.vue'
import Profile from '@/views/component/Profile.vue'
import ContactUs from '@/views/component/ContactUs.vue'
import Manner from '@/views/component/Manner.vue'
import Rates from '@/views/component/Rates.vue'
import Range from '@/views/component/Range.vue'
// import Partner from '@/views/component/Partner.vue'
import Framework from '@/views/component/Framework.vue'
export default {
    components: { AboutWe, BaseDetails, Copyright, TopTabs, Profile, Manner, ContactUs, Rates, Range, Framework },
    data() {
        return {
            activeName: this.$t('message.tabs.home')
        }
    },
    methods: {
        handleClick(val) {
            this.activeName = val.name
        },
        changeLang() {
            this.activeName = this.$t(`message.tabs.home`)
        },
        changeTabs(val) {
            this.activeName = val
        }
    },
}
</script>

<style lang="scss" scoped>
.rootBox {
    width: 100%;

    .headerTop {
        width: 100%;
        height: 32px;
        font-size: 12px;
        color: #ffffff;
        background: #1857a4;

        .content {
            width: 1280px;
            margin: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .rightTop {
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    margin-right: 50px;
                }
            }
        }
    }

    .titleBox {
        width: 1280px;
        height: 96px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .titleLeft {
            height: 100%;
            padding: 23px 0 23px;
            box-sizing: border-box;
            display: flex;
            align-items: center;

            .logo {
                width: 72px;
                height: 72px;
                object-fit: cover;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .name {
                height: 72px;
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                color: #1857a4;

                .cn {
                    font-size: 18px;
                    font-weight: 600;
                }

                .partition {
                    border-top: 1px solid #d5d4d4;
                }

                .en {
                    font-size: 12px;
                }

                p {
                    margin: 0;
                }
            }
        }
    }

    .imgBox {
        width: 100%;
        height: 300px;
        background: url('@/assets/image/收费标准2.png') no-repeat;
        background-size: 100% 100%;
    }
}

::v-deep.tabsBox .el-tabs__nav-wrap::after {
    display: none;
}

::v-deep.tabsBox .el-tabs__header {
    margin: 0;
}
</style>



<style lang="scss">
@media (max-width: 500px) {
    body {
        padding: 0;
    }

    .headerTop {
        display: none;
    }

    .titleLeft {
        padding: 0.5rem !important;
    }

    .tabsBox {
        margin-right: 0.5rem;
    }

    .el-tabs__active-bar {
        display: none;
    }
}
</style>