<template>
    <div class="tabBox">
        <div class="left">
            {{title}}
        </div>
        <div class="right">
            <span class="Breadcrumb" @click="goHome">{{$t('message.tabs.home')}}</span>
            <span>&nbsp;> {{title}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            title:{
                type: String,
                default: ''
            }
        },
        methods:{
            goHome() {
                this.$emit('changeTab',this.$t('message.tabs.home'))
            }
        }
    }
</script>

<style lang="scss" scoped>
.tabBox{
    width: 1280px;
    height: 46px;
    margin: 56px auto 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 2px solid #e1e1e1;
    .left{
        height: 100%;
        box-sizing: border-box;
        border-bottom: 2px solid #005EAB;
        font-size: 24px;
        color: #005EAB;
        position: relative;
        top: 2px;
    }
    .right{
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 14px;
        color: #686868;
        .Breadcrumb{
            font-size: 18px;
            cursor: pointer;
        }
    }
}
</style>