<template>
    <div class="bottomBox">
        <div class="titleBox">
            <ul @click="changeTabs">
                <li>{{$t('message.tabs.home')}}</li>
                <li>{{$t('message.tabs.profile')}}</li>
                <li>{{$t('message.tabs.range')}}</li>
                <li>{{$t('message.tabs.rates')}}</li>
                <li>{{$t('message.tabs.manner')}}</li>
                <li>{{$t('message.tabs.contact')}}</li>
            </ul>
        </div>
        <div class="content">
            <div class="message">
                <div class="phone">
                    <p>{{$t('message.tabs.phone')}}：( 028 ) 85189698</p>
                    <p>{{$t('message.tabs.mobile')}}：13980891763、19308182819</p>
                </div>
                <div class="emall">
                    <p>{{$t('message.tabs.fax')}}：( 028 ) 85189698</p>
                    <p>{{$t('message.tabs.email')}}：635488940@qq.com</p>
                </div>
            </div>
            <div class="address">
                {{$t('message.tabs.address')}}：{{$t('message.tabs.addressText')}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods:{
            changeTabs(e) {
                e.target.nodeName === 'LI' && this.$emit('changeTabs',e.target.innerText)
            }
        }
    }
</script>

<style lang="scss" scoped>
.bottomBox{
    width: 100%;
    height: 204px;
    background: #333333;
    .titleBox{
        width: 100%;
        margin: auto;
        text-align: center;
        border-bottom: 1px solid #5F5F5F;
        ul{
            width: 1280px;
            height: 60px;
            padding: 0;
            margin: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            li{
                list-style: none;
                font-size: 14px;
                color: #ffffff;
                text-align: center;
                transition: all .3s ease;
                cursor: pointer;
            }
            li:hover{
                color:#ada5a5;
            }
        }
    }
    .content{
        width: 1280px;
        margin: auto;
        font-size: 14px;
        color: #C0C0C0;
        .message{
            display: flex;
            .phone{
                width: 300px;
            }
        }
    }
}
</style>