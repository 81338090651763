import enLocale from 'element-ui/lib/locale/lang/en' //引入element语言包
//中文对应的英文
const en = {
    message: {
        tabs: {
            home: 'Home',
            profile: 'Intro',
            range: 'Range',
            partner: 'Partner',
            manner: 'Manner',
            contact: 'Contact',
            rates: 'Rates',
            framework: 'Framework',
            companyName: 'SICHUAN KEDAOHENG ACCOUNTING FIRM',
            companyNameOne: 'SICHUAN KEDAOHENG ACCOUNTING FIRM CO. LTD',
            companyNameTo: 'SICHUAN KEDAOHENG TAX AGENTS CO. LTD',
            companyNameThree: 'SICHUAN KEDAOHENG ENGINEERING MANAGEMRENT CO., LTD',
            service: 'Customer service hotline',
            phone: 'Phone',
            mobile: 'Tel',
            fax: 'Fax',
            email: 'Email',
            address: 'Address',
            addressText: "No.1316, Floor 13, Unit 1, Building 2, No.55 Guanghua West Fifth Road, Qingyang District, Chengdu",
            bottom: "Copyright: Sichuan Kedaoheng Accounting Firm Co., LTD. Record number: 蜀ICP备2023027968号-1; Technical support: Sichuan Xinhuateng Technology Co. LTD",
            certification: 'Company certification',
            ratesTitleA: 'Tax service fee standard',
            ratesTitleB: 'Auditing service fee standard',
            floatNumCheck: 'Enter a maximum of two decimal places',
            ratesUnit: 'Unit of calculation: ten thousand yuan',
            ratesSmall: 'Ten thousand',
            landSmall: 'square meter',
            timeSmall: 'hour',
            explain: 'attention:'
        },
        profile: {
            first: 'Sichuan Kedaoheng CPA Firm Limited (hereinafter referred to as Kedaoheng) was formerly known as Sichuan Guangda CPA Firm Limited (formerly known as: Sichuan Jiachenxin Certified Public Accountants Co., LTD., established in September 2001, is a professional service institution with independent legal personality approved by the Sichuan Provincial Department of Finance and under the direct leadership and management of Sichuan Institute of Certified Public Accountants.',
            second: 'Over the past 20 years, Kedao Heng has provided a large number of professional services for many customers, won the general praise and high trust from all walks of life, and established a professional image of integrity, efficiency, independence, objectivity and fairness in the public’s heart. Sichuan Kedaoheng Accounting Firm Co., Ltd. has been recommended by Sichuan Provincial Department of Finance as an audit unit of large and medium-sized state-owned enterprises over the years. In 2015, it was listed as an alternative pool of intermediaries by Sichuan State-owned Assets Supervision and Administration Commission; 2023-2025 Chengdu SASAC intermediary service agency. Sichuan Kedaoheng Accounting Firm Co., Ltd. is a depository institution of Chengdu Finance Bureau and a third-party depository institution for financial performance evaluation of provincial units in Sichuan Province.',
            third: 'Kedao Heng service objects cover state-owned enterprises, joint-stock enterprises, foreign-invested enterprises, collective enterprises, private (private) enterprises, public institutions, state organs, groups and legal persons and other economic organizations.',
            fourth: 'Due to the effective work of all employees, Kedao Heng has never been involved in litigation and punishment since its establishment, and has maintained the interests of the state, investors, business operators, creditors and debtors, etc., which has been praised by all sectors of society and established a good reputation in peers and all sectors of society.',
            fiveth: 'Sichuan Kedaoheng Certified Public Accountants Co., Ltd. was established in 2001 by Sichuan Finance Department (2001) No. 1112 document, registered in Chengdu Qingyang District Administration for Industry and Commerce, is a professional service institution with independent legal personality, the legal representative is Zhou Bing. The registered capital of the company is RMB 1.07 million.',
            sixth: "Kedaoheng has many talents and diversified professional services, with dozens of Chinese certified public accountants, national Certified cost engineers, certified asset appraisers, certified tax agents, real estate appraisers, land appraisers, senior accountants, senior engineers, economists and other professionals. With the Certified Public accountant qualification Certificate (Certificate No. 51010120) issued by the Ministry of Finance of the People's Republic of China, he has a high professional level, a wide range of knowledge, strong working ability and good professional ethics. The company has an honest, responsible, united and energetic management team. At present, the company has a professional team of more than 50 people, whose knowledge covers financial accounting, special audit, internal control audit, economic management, engineering audit and other professional knowledge background, and the working time is more than ten years. The company team has a deep theoretical foundation, rich professional experience, superb professional quality, excellent management ability. The company attaches great importance to customer needs, adheres to quality orientation, pays attention to people-oriented and unity and cooperation, and provides customers with a variety of high-quality professional services in a comprehensive and efficient manner."
        },
        range: {
            audit: 'Audit',
            auditText: {
                first: '· Audit, capital verification, profit forecast review and internal control evaluation of stock reorganization and listing',
                second: "· Independent financial advisor's report",
                third: '· Audit, review and internal audit of accounting statements of various enterprises',
                fourth: '· Audit of business liquidation accounting statements',
                fiveth: "· Special investigation of fraud or error",
                sixth: '· Participated in the mediation of economic disputes and assisted in the identification of evidence in civil and commercial cases',
                seventh: '· Various other purpose-specific audit and other assurance services',
            },
            financial: "Financial consultant, management consulting",
            financialText: {
                first: "· Financial consultant for joint-stock companies",
                second: "· Financial consultant for foreign-invested enterprises",
                third: "· Professional consulting on start-up (risk) investment",
                fourth: "· Enterprise risk assessment",
                fiveth: "· Project feasibility study and project evaluation",
                sixth: "· Performance evaluation and assessment consultation",
                seventh: "· Provide professional advice for business management, restructuring, liquidation and other economic activities",
            },
            consultation: "Tax consultation",
            consultationText: {
                first: "· Enterprise tax planning",
                second: "· Tax compliance review and other special tax investigations",
                third: "· Tax advice related to mergers and acquisitions",
                fourth: "· Tax consulting related to asset restructuring",
                fiveth: "· Tax agency services",
                sixth: "· Professional training services",
            },
            enterprise: "Enterprise merger and acquisition and asset reorganization",
            enterpriseText: {
                first: "· Participated in the formulation of corporate merger and acquisition/reorganization plans",
                second: "· Provide solutions to tax, financial and accounting issues involved in M&A/restructuring",
                third: "· Help to find and screen target enterprises",
                fourth: "· Conduct prudential investigations",
                fiveth: "· Participate in contract negotiations"
            },
            performance: "Performance evaluation",
            performanceText: {
                first: "· Prior performance evaluation",
                second: "· Department expenditure performance evaluation",
                third: "· Project expenditure performance evaluation",
                fourth: "· Financial policy performance evaluation",
                fiveth: "· Enterprise performance evaluation"
            },
            infrastructure: "Infrastructure audit",
            infrastructureText: {
                first: "· Prepare project proposal, feasibility study report and preliminary design document of construction project",
                second: "· Evaluate and consult project proposals, feasibility study reports and preliminary engineering design documents of construction projects",
                third: "· Construction project investment management and estimation;",
                fourth: "· Preparation and review of construction project estimates (estimates)",
                fiveth: "· Preparation and review of pretenders for civil construction, installation and municipal engineering of construction projects",
                sixth: "· Preparation and service of bidding documents for construction projects",
                seventh: "· Review the construction contract price of state-owned and collective investment projects",
                eighth: "· Check the authenticity of the order price of materials, equipment, tools and appliances",
                ninth: "· Review the validity of visa change and negotiation records",
                tenth: "· Review the impact of engineering design changes and on-site negotiation records on the adjustment of project cost",
                eleventh: "· Review the legality and rationality of construction contracts and agreements",
                twelfth: "· Prepare and audit the image progress settlement of construction and installation projects",
                thirteenth: "· Management of project advance payment, material preparation payment, progress payment, etc",
                fourteenth: "· Dynamic management of construction project cost",
                fifteenth: "· Review the claims of construction projects",
                sixteenth: "· Construction project cost consultation and project construction management suggestions",
                seventeenth: "· Project completion settlement and appraisal",
                eighteenth: "· Construction project value assessment"
            }
        },
        partner: {
            title: "Over the past 20 years, Kedaoheng has provided various professional services for many customers, won the universal praise and high trust from all walks of life, and established a good corporate image of integrity, efficiency, independence, objectivity and impartiality in the public mind.",
            enterprise: "Enterprise",
            scjt: "Sichuan Trading Group",
            scnt: "Sichuan Energy Investment Group",
            schg: "Sichuan Chemical Industry Holding Group",
            scch: "Sichuan Chuanhua Group",
            sljc: "Shuangliu Airport Group",
            kdjc: "Kangding Airport Group",
            gwsc: "State Grid Sichuan Electric Power Company",
            zgdx: "China Telecom Sichuan Company",


            scgp: "Sichuan High Frequency Group Co. LTD",
            scxx: "Sichuan Xinxing Group Co. LTD",
            scsd: "Sichuan Shengda Group Co. LTD",
            schnwk: "Sichuan Huaneng Wankang Electric Power Co. LTD",
            schd: "Sichuan Hengding Industrial Co. LTD",
            scxc: "Sichuan Xinchuan Aviation Instrument Co. LTD",


            schn: "Sichuan Huaneng Electric Power Co. LTD",
            sccz: "Sichuan Chuanzi Construction Engineering Consulting Co., LTD",
            scyl: "Sichuan Wishlong Co., LTD",
            cdjg: "Chengdu Construction Engineering Group",
            cdct: "Chengdu City Investment Group",
            cdxc: "Chengdu Xingcheng Group",
            cdjl: "Chengdu Jule (Enterprise) Group",


            zgcs: "China Institute of Testing Technology",
            zccs: "Zhongtest TESTING Technology Co., LTD",
            pxgy: "Pixian state assets investment management company",
            cdth: "Chengdu Tanghu Group",
            cdly: "Chengdu Lianyi Group Company",
            cdsy: "Chengdu Shuangyu Group",
            cdee: "Chengdu Er 'e Industrial Co. LTD",


            xzsy: "Administrative institution",
            scsg: "Sichuan SASAC",
            scsj: "Sichuan Provincial Department of Economics and Information Technology",
            scsc: "Sichuan Provincial Department of Finance",
            scsjd: "Sichuan Drug Rehabilitation Administration",
            scsyj: "Sichuan Metallurgical Industry Department",
            sckx: "Sichuan Association for Science and Technology",
            scsym: "Sichuan Immigration Office",
            cdsg: "Chengdu SASAC",


            cdsjx: "Chengdu Economy and Information Technology Bureau",
            cdscz: "Chengdu Finance Bureau",
            cdsty: "Chengdu Sports Bureau",
            whqsj: "Wuhou District Audit Bureau",
            pxczj: "Pixian County Finance Bureau",
            pxsjj: "Pixian County Audit Bureau",
            pxdsj: "Pixian Local Tax Bureau",
            wjsjj: "Wenjiang Audit Bureau",
            ncsjx: "Nanchong City Economy and Information Bureau",
            lszjx: "Liangshan State Bureau of Economy and Information Technology"
        },
        manner: {
            companyaim: "1.Company aim",
            serviceConcept: "2.service concept",
            companyPrinciples: "3. Company principles",
            one: 'Kedao Heng takes “independence, objectivity, fairness, service, quality, efficiency” as the company’s purpose.As a professional service organization, Kedao Heng is service- oriented, emphasizing the quality of service and improving the efficiency of service.To provide practical, efficient and quality assurance services for our customers.',
            two: 'Kedaoheng adheres to the service concept of "finding problems, analyzing problems and solving problems" for customers. Kedaoheng adhere to the formality, real service for customers. For each customer, we must carefully analyze, help customers comb, standardize and improve. Being able to find problems, analyze problems and find solutions for customers is the greatest value embodiment of our service concept.',
            three: 'Kedaoheng sticks to the bottom line and principle of "no false reports". Do not cheat, strictly abide by the confidentiality system of the industry. Company personnel do not accept any gifts, cash and physical objects in the process of working; Adhere to the quality first, ensure the accuracy of the project results. Each member of the project team sent by the company is a high or intermediate professional with high knowledge level and professional skills. Every data in the project is checked one by one with a serious and responsible attitude to ensure the accuracy and scientific nature of the project results.'
        },
        contact: {
            name: "Sichuan Kedaoheng Accounting Firm Co. LTD",
            address: "Address: No.1316, Floor 13, Unit 1, Building 2, No.55 Guanghua West Fifth Road, Qingyang District, Chengdu",
            linkMan: "Contact person: Zhou Bing",
            specialPlane: 'special plane：(028) 85189698',
            phone: "Tel: 13980891763、19308182819",
            fax: "Fax: (028) 87596225",
        },
        ratesItem: {
            taxConsultation: 'Tax consultation',
            consultant: 'Professional tax consultant',
            taxPlanning: 'Tax planning',
            taxSettlement: 'Verification of corporate income tax settlement',
            verification: 'Verification of pre-tax deduction of enterprise asset losses',
            taxLiquidation: 'Verification of land value-added tax liquidation',
            qyzxqs: 'Verification of enterprise cancellation and liquidation',
            hourly: 'Hourly fee basis',


            accountingRuleA: 'The input amount is the total assets or total income, which follows the principle of high',
            accountingRuleB: '150% for tax advice and 200% for difficult matters',
            accountingRuleC: 'The basic fee is based on a professional tax adviser, plus a variable fee',
            accountingRuleD: 'Please enter the area involved in clearing authentication',
            accountingRuleE: 'Please enter the estimated time required',
            accountingRuleF: 'Does not include simple and simplified write-offs',
            accountingRuleG: 'Please enter the carrying cost of assets',


            holderBig: 'Please enter the amount',
            holderLand: 'Please enter the land area',
            holderTime: 'Please enter time'
        },
        ratesItemTo: {
            taxConsultation: 'Audit of annual financial statements',
            consultant: 'Capital verification',
            taxPlanning: 'Economic responsibility audit',
            taxSettlement: 'Restructuring audit',
            verification: 'Audit of financial revenue and expenditure',
            taxLiquidation: 'Audit of final accounts for capital construction projects',
            qyzxqs: 'Other auditing business as stipulated by laws and regulations',
            hourly: 'Merger and division liquidation audit',
            financialStatement: 'Financial statement review',
            hourlyRate: 'Charge by time',
            accountingRuleA: 'The input amount is the total assets or total income, which follows the principle of high',
            accountingRuleB: '150% for tax advice and 200% for difficult matters',
            accountingRuleC: 'The basic fee is based on a professional tax adviser, plus a variable fee',
            accountingRuleD: 'Please enter the area involved in clearing authentication',
            accountingRuleE: 'Please enter the estimated time required',
            accountingRuleF: 'Does not include simple and simplified write-offs',
            accountingRuleG: 'Please enter the carrying cost of assets',
            accountingRuleH: 'It involves auditing financial statements',
            accountingRuleI: 'No audit of financial statements is involved',
            accountingRuleJ: 'Please enter the amount of funds required for verification',


            holderBig: 'Please enter the amount',
            holderLand: 'Please enter the land area',
            holderTime: 'Please enter time'
        },
        info: {
            userinfo: 'userinfo'
        },
        table: {
            item_number: "item number"
        }

    },
    ...enLocale
}

export default en;